import React, { useEffect, useState } from "react";
import {
  Box,
  useToast,
  Button,
  Heading,
  TableContainer,
  Tbody,
  Tr,
  Divider,
  Td,
  Table,
  Text,
  Flex,
  Badge,
  useDisclosure,
  Tooltip,
  Select,
} from "@chakra-ui/react";
import axios from "axios";
import style from "./MyLeads.module.css";
import EditForm from "./EditForm/EditForm";
import DeleteForm from "./DeleteForm/DeleteForm";
import { handldatetransform } from "./code";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Preview from "./Preview/Preview";
import { useLocation } from "react-router-dom";

const truncateText = (text, wordLimit) => {
  const words = text.split(" ");
  return words.length > wordLimit 
    ? words.slice(0, wordLimit).join(" ") + "..." 
    : text;
};

const MyLeads = () => {
  const [data, setData] = useState([]);
  const toast = useToast();
  const [change, setChange] = useState(0);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const { onOpen } = useDisclosure();
  const location = useLocation();

  const handlechange = () => {
    setChange((prev) => prev + 1);
  };

  const fetchQuery = async (currentPage = 1) => {
    setLoading(true);
    setData([]);
    try {
      let id = localStorage.getItem("astadid") || undefined;
      let authorization = localStorage.getItem("astadToken") || undefined;
      let head = { id, authorization, "Content-type": "application/json" };

      if (!id || !authorization) {
        toast({
          title: "Kindly log in to send message.",
          description: "Login required for sending message.",
          status: "error",
          duration: 2000,
          position: "top-right",
        });
        return;
      }
      const response = await axios.get(`${process.env.REACT_APP_URL}/leadForm/list?page=${currentPage}`, { headers: head });
      setData(response.data.data);
      setTotalPages(response.data.total);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const handleResponse = (data, status) => {
    axios
      .patch(
        `${process.env.REACT_APP_URL}/leadForm/${data._id}`,
        {
          name: data?.name,
          mobile: data?.mobile,
          email: data?.email,
          propertyType: data?.propertyType,
          isMobileVisible: data?.isMobileVisible,
          formType: data?.formType,
          description: data?.description,
          verificationState: status,
          leadFormState: data?.leadFormState,
        },
        {
          headers: {
            id: localStorage.getItem("astadid"),
            authorization: localStorage.getItem("astadToken"),
            "Content-type": "application/json",
          },
        }
      )
      .then((response) => {
        toast({
          title: `Lead ${status.toLowerCase()} successfully!`,
          status: "success",
          duration: 2000,
          position: "top-right",
        });
        handlechange();
      })
      .catch((error) => {
        toast({
          title: `Error sending response ${error.response.msg}`,
          status: "error",
          duration: 2000,
          position: "top-right",
        });
      });
  };

  useEffect(() => {
    fetchQuery(page);
  }, [change, page]);

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setPage(newPage);
    }
  };

  return (
    <Box margin={"30px auto 80px auto"}>
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "10px 40px",
        }}
      >
        <Heading>My Leads</Heading>
      </Box>

      <Box className={style.leads}>
        {loading &&
          [1, 2, 3]?.map((e) => (
            <Box key={e} paddingRight={"20px"} as={"div"}>
              <Box display={"flex"} justifyContent={"space-between"}>
                <Heading size={"md"} textTransform={"capitalize"} width={"30%"}>
                  <Skeleton baseColor="#9AE6FF" />
                </Heading>
                <Text width={"30%"}>
                  <Skeleton baseColor="#9AE6FF" />
                </Text>
              </Box>
              <Divider border={"1px solid rgb(150, 150, 150)"} />
              <TableContainer>
                <Table size="sm" variant="simple">
                  <Tbody>
                    <Tr display={"flex"}>
                      <Td flex={1}>Mobile </Td>
                      <Td flex={1}>
                        <Skeleton baseColor="#9AE6FF" />
                      </Td>
                    </Tr>
                    <Tr display={"flex"}>
                      <Td flex={1}>Email </Td>
                      <Td flex={1}>
                        <Skeleton baseColor="#9AE6FF" />
                      </Td>
                    </Tr>
                    <Tr display={"flex"}>
                      <Td flex={1}>Form Type </Td>
                      <Td flex={1}>
                        <Skeleton baseColor="#9AE6FF" />
                      </Td>
                    </Tr>
                    <Tr display={"flex"}>
                      <Td flex={1}>Property Type </Td>
                      <Td flex={1}>
                        <Skeleton baseColor="#9AE6FF" />
                      </Td>
                    </Tr>
                    <Tr display={"flex"}>
                      <Td flex={1}>Verification Status </Td>
                      <Td flex={1}>
                        <Skeleton baseColor="#9AE6FF" />
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>
              <Box display={"flex"} gap={"4px"} marginLeft={"16px"}>
                <Text fontSize={"sm"}>Description - </Text>
                <Text fontSize={"sm"} className={style.maxline}>
                  <Skeleton baseColor="#9AE6FF" />
                </Text>
              </Box>
              <Flex
                width={"100%"}
                marginTop={"10px"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Button size="sm" borderRadius={0} colorScheme={"green"}>
                  <Skeleton baseColor="#2f855a" width={"60px"} />
                </Button>
                <Button colorScheme="blue" size="sm" borderRadius={0}>
                  <Skeleton baseColor="#2b6cb0" width={"60px"} />
                </Button>
                <Button size="sm" colorScheme="red" borderRadius={0}>
                  <Skeleton baseColor="#c53030" width={"60px"} />
                </Button>
              </Flex>
            </Box>
          ))}
        {data?.map((detail) => (
          <Box key={detail._id} paddingRight={"20px"} as={"div"} >
            <Box display={"flex"} justifyContent={"space-between"}>
              <Heading size={"md"} textTransform={"capitalize"}>
                {detail?.name}
              </Heading>
              <Box fontSize={"sm"}>
                <Badge
                  variant="subtle"
                  color={"#636262"}
                  colorScheme="blue"
                  padding={"0 5px"}
                >
                  {handldatetransform(detail.createdOn)}
                </Badge>
              </Box>
            </Box>
            <Divider border={"1px solid rgb(150, 150, 150)"} />
            <TableContainer>
              <Table size="sm" variant="simple">
                <Tbody>
                  <Tr>
                    <Td>Mobile </Td>
                    <Td>{detail?.mobile} </Td>
                  </Tr>
                  <Tr>
                    <Td>Email </Td>
                    <Td>{detail?.email} </Td>
                  </Tr>
                  <Tr>
                    <Td>Form Type </Td>
                    <Td>{detail?.formType} </Td>
                  </Tr>
                  <Tr>
                    <Td>Property Type </Td>
                    <Td>{detail?.propertyType} </Td>
                  </Tr>
                  <Tr>
                    <Td>Verification Status </Td>
                    <Td>
                      <Badge
                        variant="outline"
                        fontWeight="bold"
                        colorScheme={
                          detail.verificationState === "Approved"
                            ? "green"
                            : "blue"
                        }
                      >
                        {detail?.verificationState}
                      </Badge>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td> Visibility </Td>
                    <Td>{detail?.leadFormState}</Td>
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>
            <Box display={"flex"} gap={"4px"}>
              <Text fontSize={"sm"} marginLeft={"16px"}>
                Description&nbsp;-{" "}
              </Text>
              <Text fontSize={"sm"} className={style.maxline}>
                {truncateText(detail.description, 12)}
              </Text>
            </Box>
            <Select
              size="sm"
              borderRadius={0}
              onChange={(e) => handleResponse(detail, e.target.value)}
              placeholder="Select status"
            >
              <option value="Approved">Approved</option>
              <option value="Rejected">Reject</option>
              <option value="Pending">Pending</option>
              <option value="Fulfilled">Fulfilled</option>
            </Select>
            <Flex
              width={"100%"}
              marginTop={"10px"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Preview detail={detail} />
              <EditForm detail={detail} handlechange={handlechange} />
              <DeleteForm queryid={detail?._id} handlechange={handlechange} />
            </Flex>
          </Box>
        ))}
      </Box>

      <Flex justifyContent="center" marginTop="20px">
        <Button
          size="sm"
          onClick={() => handlePageChange(page - 1)}
          disabled={page === 1}
        >
          Previous
        </Button>
        <Text marginX="10px">Page {page} of {totalPages}</Text>
        <Button
          size="sm"
          onClick={() => handlePageChange(page + 1)}
          disabled={page === totalPages}
        >
          Next
        </Button>
      </Flex>
    </Box>
  );
};

export default MyLeads;
