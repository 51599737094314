
export const USER_LOGIN = "user/login";  
export const USER_SIGUP = "user/sigup";  
export const USER_DATA_LOADING = "user/data/loading";  
export const USER_DATA_ERROR = "user/data/error";  


export const USER_LOGOUT = "user/logout"; 
export const USER_PRE_LOGIN = "user/prelogin";  

 



