import { Box, Heading, Button, Text, Input, InputGroup, InputRightElement, Select } from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import style from "./Admin.module.css";
import AdminDetail from './AdminDetail';
import Loader from '../../components/Loader/Loader';

const AdminPage = () => {
  const userdetail = useSelector((state) => state.admindetail);
  const [searchParam, setSearchParams] = useSearchParams();
  const prePage = searchParam.get("page");
  const Inputdetail = searchParam.get("search");
  const preRole = searchParam.get("role");
  const [data, setData] = useState([]);
  const [userRole, setUserRole] = useState(preRole || "");
  const [inp, setInp] = useState(Inputdetail || "");
  const [page, setPage] = useState(prePage || 1);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);

  const allAdmin = async (currentPage = 1, query = '', role = '') => {
    try {
      let obj = {
        id: userdetail.id,
        authorization: userdetail.token,
        'Content-Type': 'application/json',
      };
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/admin/all?page=${currentPage}&search=${query}&role=${role}`,
        { headers: obj }
      );
      console.log(response.data.data);
      setData(response.data.data);
      setTotalPages(response.data.total);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const handleBlockChange = async (userId, statement) => {
    try {
      let obj = {
        id: userdetail.id,
        authorization: userdetail.token,
        'Content-Type': 'application/json',
      };

      const body = {
        id: userId,
        status: statement
      };

      console.log(statement);
      setLoading(true);
      await axios.post(`${process.env.REACT_APP_URL}/admin/block`, body, { headers: obj }).then((e) => {
        console.log(e);
        setLoading(false);
      });
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const handleVerifieChange = async (userId, validstate) => {
    try {
      let obj = {
        id: userdetail.id,
        authorization: userdetail.token,
        'Content-Type': 'application/json',
      };

      let body = {
        id: userId,
        status: validstate
      };
      setLoading(true);
      await axios.post(`${process.env.REACT_APP_URL}/admin/verifyUser`, body, { headers: obj }).then((e) => {
        allAdmin();
        setLoading(false);
      });
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const handleBlocking = async (myid, status) => {
    try {
      let obj = {
        id: userdetail.id,
        authorization: userdetail.token,
        'Content-Type': 'application/json',
      };

      let body = {
        id: myid,
        status
      };

      setLoading(true);
      await axios.post(`${process.env.REACT_APP_URL}/admin/block`, body, { headers: obj }).then((e) => {
        console.log(e.data);
        setLoading(false);
      });
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    let obj = {};

    inp && (obj.search = inp);
    page && (obj.page = page);
    userRole && (obj.role = userRole);

    setSearchParams(obj);

    // Remove this call from useEffect
    // allAdmin(page, inp, userRole);
  }, [inp, page, userRole, setSearchParams]);

  useEffect(() => {
    allAdmin(page, inp, userRole);
  }, []);

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setPage(newPage);
      allAdmin(newPage, inp, userRole);
    }
  };

  const handleSearchChange = (event) => {
    setInp(event.target.value);
  };

  const handleSearch = () => {
    setPage(1);
    allAdmin(1, inp, userRole);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const handleRoleChange = (event) => {
    setUserRole(event.target.value);
    setPage(1);
    allAdmin(1, inp, event.target.value);
  };

  return (
    <Box marginTop={"20px"}>
      <Heading> Admin Detail </Heading>
      <Box display={"flex"} >
        <Select placeholder="Select role" value={userRole} onChange={handleRoleChange} margin={"20px 20px"}>
          <option value="customer">Customer</option>
          <option value="employee">Employee</option>
          <option value="agent">Agent</option>
          <option value="admin">Admin</option>
          <option value="super_admin">Super Admin</option>
        </Select>
        <InputGroup margin={"20px 20px"}>
          <Input
            placeholder="Search by name, mobile, or email"
            value={inp}
            onChange={handleSearchChange}
            onKeyDown={handleKeyDown}
          />
          <InputRightElement width="4.5rem">
            <Button h="1.75rem" size="sm" onClick={handleSearch}>
              Search
            </Button>
          </InputRightElement>
        </InputGroup>
      </Box>

      <Box className={style.adminone}>
        {data.map((e) => (
          <AdminDetail key={e._id} e={e} handleBlocking={handleBlocking} handleVerifieChange={handleVerifieChange} />
        ))}
      </Box>
      {loading && <Loader />}
      {userdetail.isLoading && <Loader />}
      <Box display="flex" justifyContent="center" alignItems="center" marginTop="20px">
        <Button
          onClick={() => handlePageChange(page - 1)}
          isDisabled={page === 1}
        >
          Previous
        </Button>
        <Text mx="10px">Page {page} of {totalPages}</Text>
        <Button
          onClick={() => handlePageChange(page + 1)}
          isDisabled={page === totalPages}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
};

export default AdminPage;
