import React, { useEffect, useState } from "react";
import axios from "axios";
import { Box, Button, Text, Select } from "@chakra-ui/react";
import style from "../Propertystate.module.css";
import Boxdet from "../Boxdlt/Boxdet";

const Rejected = ({ datachange, setLoader }) => {
  const [property, setProperty] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [sortOption, setSortOption] = useState("latest");

  

  const propertydetail = async (page = 1, sort = "latest") => {
    setLoader(true);
    try {
      let id = localStorage.getItem("astadid");
      let token = localStorage.getItem("astadToken");

      let obj = {
        id,
        authorization: token,
        "Content-Type": "application/json",
      };

      const response = await axios.get(
        `${process.env.REACT_APP_URL}/admin/verificationStateList?adminState=Rejected&page=${page}`,
        {
          headers: obj,
        }
      );
      let sortedData = response.data.data;
      if (sort === "latest") {
        sortedData = sortedData.sort((a, b) => new Date(a.date) - new Date(b.date));
      } else {
        sortedData = sortedData.reverse();
      }

      setProperty(sortedData);
      setTotalPages(response.data.totalPages);
      setLoader(false);
    } catch (err) {
      console.log(err);
      setLoader(false);
    }
  };

  const handleChangeState = async (propertyId, status) => {
    setLoader(true);
    let id = localStorage.getItem("astadid");
    let token = localStorage.getItem("astadToken");

    let obj = {
      id,
      authorization: token,
      "Content-Type": "application/json",
    };
    try {
      let body = {
        id: propertyId,
        status,
      };
      await axios.post(
        `${process.env.REACT_APP_URL}/admin/verificationState`,
        body,
        { headers: obj }
      );
      setLoader(false);
    } catch (err) {
      console.log(err);
      setLoader(false);
    }
    propertydetail(currentPage);
  };

  useEffect(() => {
    propertydetail(currentPage, sortOption);
  }, [datachange, currentPage, sortOption]);

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };
  const handleSortChange = (event) => {
    setSortOption(event.target.value);
  };

  return (
    <Box>
       <Box display="flex" justifyContent="end" alignItems="center" mb={4}>
        <Select width={"30vw"} float={"right"} value={sortOption} onChange={handleSortChange}>
          <option value="latest">Sort by Latest</option>
          <option value="oldest">Sort by Oldest</option>
        </Select>
      </Box>
      <Box className={style.topsection}>
        {property.map((e) => (
          <Boxdet
            e={e}
            key={e._id}
            handleChangeState={handleChangeState}
            disabled={"Rejected"}
          />
        ))}
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center" marginTop="20px">
        <Button
          onClick={() => handlePageChange(currentPage - 1)}
          isDisabled={currentPage === 1}
        >
          Previous
        </Button>
        <Text mx="10px">Page {currentPage} of {totalPages}</Text>
        <Button
          onClick={() => handlePageChange(currentPage + 1)}
          isDisabled={currentPage === totalPages}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
};

export default Rejected;
