import { Box, Heading, Button, Text, Input, InputGroup, InputRightElement } from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import style from "./User.module.css";
import UserDetail from './UserDetail/UserDetail';
import Loader from '../../components/Loader/Loader';
import { useSelector } from 'react-redux';

const UserPage = () => {
  const userdetail = useSelector((state) => state.admindetail); 
  const [data, setData] = useState([]);
  const [load, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');

  const firstCall = async (page = 1, query = '') => {
    try {
      let obj = {
        id: userdetail.id, 
        authorization: userdetail.token, 
        'Content-Type': 'application/json',
      };
      setLoading(true);
      const response = await axios.get(`${process.env.REACT_APP_URL}/admin/all?role=customer&page=${page}&search=${query}`, { headers: obj });
      console.log(response.data.data);
      setData(response.data.data);
      setTotalPages(response.data.total);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  }

  useEffect(() => {
    firstCall(currentPage, searchQuery);
  }, [currentPage]);

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSearch = () => {
    setCurrentPage(1);
    firstCall(1, searchQuery);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <>
      {load ? <Loader /> : (
        <Box display={"grid"} gap={6} margin={"20px auto "} >
          <Heading as={"h1"} size={"lg"}> User Detail </Heading>
          <InputGroup width={"50vw"}  margin={"auto auto"}>
            <Input
              placeholder="Search by name, mobile, or email"
              value={searchQuery}
              onChange={handleSearchChange}
              onKeyPress={handleKeyPress}
            />
            <InputRightElement width="4.5rem">
              <Button h="1.75rem" size="sm" onClick={handleSearch}>
                Search
              </Button>
            </InputRightElement>
          </InputGroup>
          <Box gap={6} w={"96%"} margin={"auto"} className={style.userDetail} >
            {data?.map((e) => (
              <UserDetail e={e} key={e._id} />
            ))}
          </Box>
          <Box display="flex" justifyContent="center" alignItems="center" marginTop="20px">
            <Button
              onClick={() => handlePageChange(currentPage - 1)}
              isDisabled={currentPage === 1}
            >
              Previous
            </Button>
            <Text mx="10px">Page {currentPage} of {totalPages}</Text>
            <Button
              onClick={() => handlePageChange(currentPage + 1)}
              isDisabled={currentPage === totalPages}
            >
              Next
            </Button>
          </Box>
        </Box>
      )}
    </>
  )
}

export default UserPage;
